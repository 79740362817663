import { onBeforeUnmount } from 'vue';
import { Url } from 'o365-utils';
import { getOrCreateProcedure, context } from 'o365-modules';
import { type DataObject, type DataItemModel } from 'o365-dataobject';

interface ICheckoutButtonReturnType {
    checkoutAppID?: string | null,
    enableCheckout?: boolean,
    handleUpdateOfflineItems: Function,
    getCheckedOutBySomeoneElse: Function,
}

export default function useCheckoutButton(dataObject: DataObject, checkoutProcedure: string): ICheckoutButtonReturnType {
    /* PUBLIC MEMBERS */
    const checkoutAppID = Url.getParam('checkout-app-id') ?? null;
    const enableCheckout = Url.getParam('enable-checkout') === 'true';

    const handleUpdateOfflineItems = async (rowOrRows: DataItemModel | Array<DataItemModel>, action: string) => {
        if (rowOrRows.constructor === Array) {
            const rows = <Array<DataItemModel>>rowOrRows;
            await _updateItems(rows, action);
        } else {
            const row = <DataItemModel>rowOrRows;
            await _updateItem(row, action);
        }
    }

    const getCheckedOutBySomeoneElse = (row: DataItemModel) => {
        return !row.CheckedOutByMe && row.CheckedOutBy_ID !== null && row.CheckedOutBy_ID !== undefined;
    }

    /* PRIVATE MEMBERS */
    const _updateItem = async (row: DataItemModel, action: string): Promise<void> => {
        if (!enableCheckout || !checkoutAppID || getCheckedOutBySomeoneElse(row)) {
            return;
        }

        row._handlingCheckoutRequest = true;

        const proc = getOrCreateProcedure({
            id: 'procUpdateOfflineCheckInOut',
            procedureName: checkoutProcedure
        });

        const procOptions = {
            AppID: checkoutAppID,
            Multiple: false,
            Action: action,
            ID: row.ID,
        };

        try {
            await proc.execute(procOptions);

            if (parent) {
                parent.postMessage('{"type": "checkOutUpdate"}');
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            dataObject.refreshRow(row.index).then((row) => {
                if (row) row._handlingCheckoutRequest = false;
            });
        }
    }

    const _updateItems = async (rows: Array<DataItemModel>, action: string): Promise<void> => {
        if (!enableCheckout || !checkoutAppID) {
            return;
        }
        
        rows.forEach((row: DataItemModel) => {
            row._handlingCheckoutRequest = true;
        });

        const proc = getOrCreateProcedure({
            id: 'procUpdateOfflineCheckInOut',
            procedureName: checkoutProcedure,
        });

        const procOptions = {
            AppID: checkoutAppID,
            Multiple: true,
            Action: action,
            IDs: rows.map(row => [row.ID]),
        };

        try {
            await proc.execute(procOptions);
            
            if (parent) {
                parent.postMessage('{"type": "checkOutUpdate"}');
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            rows.forEach(row => {
                dataObject.refreshRow(row.index).then((row) => {
                    if (row) row._handlingCheckoutRequest = false;
                });
            });

            dataObject.selectionControl.selectAll(false);
        }
    }

    const _onMessage = (event: Event) => {
        try {
            const messageObject = JSON.parse(event.data);

            switch (messageObject.type) {
                case 'contextUpdate':
                    context.setContext(messageObject.id);
                    break;
            }
        } catch (_reason) {}
    }

    if (document.body.hasAttribute('o365-offline-context-selector-listener')) {
        window.addEventListener('message', _onMessage, false);
        document.body.setAttribute('o365-offline-context-selector-listener', 'true');
    }

    onBeforeUnmount(() => {
        window.removeEventListener('message', _onMessage, false);
        document.body.removeAttribute('o365-offline-context-selector-listener');
    });

    return { checkoutAppID, enableCheckout, handleUpdateOfflineItems, getCheckedOutBySomeoneElse };
};